import { SecurityPinScene } from '@noah-labs/fe-shared-ui-signing';
import { useSigning } from '../hooks/useSigning';

type PpSecurityPin = {
  createPinRoute: string;
  resetPinRoute: string;
  updatePinRoute: string;
};

export function SecurityPin({
  createPinRoute,
  resetPinRoute,
  updatePinRoute,
}: PpSecurityPin): React.ReactElement {
  const { sdStatus } = useSigning();

  return (
    <SecurityPinScene
      createPinRoute={createPinRoute}
      pinSetupRequired={Boolean(sdStatus?.pinSetupRequired)}
      resetPinRoute={resetPinRoute}
      updatePinRoute={updatePinRoute}
    />
  );
}
