import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Stack,
} from '@mui/material';
import { PinInput, PrimaryButton } from '@noah-labs/fe-shared-ui-components';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { pinLength } from '@noah-labs/shared-cryptography';
import type { Resolver, UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import type { TpPinForm } from '../../types';
import { verifyPinSchema } from '../../utils/securityValidation';

export type PpSignDialog = {
  onSubmit: (values: TpPinForm, setError: UseFormSetError<TpPinForm>) => Promise<void>;
};

const formId = 'verify-pin';

export function SignDialog({ onSubmit }: PpSignDialog): React.ReactElement {
  const methods = useForm<TpPinForm>({
    defaultValues: {
      pin: '',
    },
    mode: 'onSubmit',
    resolver: zodResolver(verifyPinSchema) as Resolver<TpPinForm>,
  });

  const { pin } = useWatch({ control: methods.control });

  return (
    <React.Fragment>
      <DialogTitle>Enter your security PIN</DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          <DialogContentText>Enter your PIN code to verify this transaction</DialogContentText>
          <FormProvider {...methods}>
            <form
              id={formId}
              onSubmit={async (e): Promise<void> => {
                await methods.handleSubmit(async (values) => {
                  await onSubmit(values, methods.setError);
                })(e);
                // reset the value but keep the field errors as they have contain important info
                methods.setValue('pin', '');
              }}
            >
              <PinInput name="pin" pinLength={pinLength} />
            </form>
          </FormProvider>
          <Link
            href={userRoutes.settings.securityPin.path}
            textAlign="center"
            variant="paragraphBodyM"
          >
            Forgot PIN?
          </Link>
        </Stack>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          color="primaryBrand"
          data-qa="continue-button"
          disabled={typeof pin === 'string' && pin.length !== pinLength}
          form={formId}
          loading={methods.formState.isSubmitting}
          type="submit"
        >
          Continue
        </PrimaryButton>
      </DialogActions>
    </React.Fragment>
  );
}
