import type { AccountType, CurrencyCode, Network } from '@noah-labs/shared-schema-gql';
import { capitalizeFirstLetter } from '@noah-labs/shared-util-vanilla';
import { cryptoCurrencyFromCode } from '../cryptoCurrencies';
import { cryptoNetworkFromId } from '../cryptoNetworks';
import { useRouter } from '../routing/useRouter';
import type { TpCryptoCurrencyUI, TpCryptoNetworkUI } from '../types';

type TpWalletRouterLowerParams = {
  accountType: Lowercase<AccountType> | '';
  currencyCode: Lowercase<CurrencyCode> | '';
  network: Lowercase<Network> | '';
};

export type TpNormalisedParams = {
  AccountType: AccountType;
  cryptoCurrency: TpCryptoCurrencyUI;
  cryptoNetwork: TpCryptoNetworkUI | undefined;
  params: TpWalletRouterLowerParams;
};

export type PpUseWallet = {
  accountType: AccountType | '';
  currencyCode: CurrencyCode | '';
  network?: Network | '';
};

const emptyWalletParams: PpUseWallet = { accountType: '', currencyCode: '', network: '' };

export function useWalletParams(fallback: PpUseWallet = emptyWalletParams): TpNormalisedParams {
  const { useParams } = useRouter();
  const params = {
    ...fallback,
    ...useParams(),
  };

  const at = params.accountType.toLowerCase();
  const cc = params.currencyCode.toLowerCase();
  const nt = params.network?.toLowerCase();

  const AT = capitalizeFirstLetter(at);
  const CC = params.currencyCode.toUpperCase();

  return {
    AccountType: AT as AccountType,
    cryptoCurrency: cryptoCurrencyFromCode(CC as CurrencyCode),
    cryptoNetwork: nt ? cryptoNetworkFromId(nt as Lowercase<Network>) : undefined,
    params: {
      accountType: at as Lowercase<AccountType>,
      currencyCode: cc as Lowercase<CurrencyCode>,
      network: nt as Lowercase<Network>,
    },
  };
}
