import { useState } from 'react';
import { CreatePinRecover, EnterSecretPhrase } from '@noah-labs/fe-shared-feature-signing';
import { AppContainer, AppHeaderTitle, Switch404 } from '@noah-labs/fe-shared-ui-components';
import { PinSuccessScene } from '@noah-labs/fe-shared-ui-signing';
import { userRoutes, walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { SafeWrapper } from '@noah-labs/shared-cryptography';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AppHeaderData } from '../../components/layout/AppHeaderData';
import { AccessControlData } from '../auth/AccessControlData';

/**
 * Users attempt to recover their wallet/pin with their secret phrase.
 */
export function RecoverPinWithSecretPhraseRouter(): React.ReactElement {
  const [phrase, setPhrase] = useState<SafeWrapper<string>>();

  return (
    <Switch404>
      <Route exact path={userRoutes.pin.reset.secretPhrase.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.Pin]}>
          <AppContainer headTitle={userRoutes.pin.reset.secretPhrase.path}>
            <AppHeaderData backButton helpButton>
              <AppHeaderTitle>{userRoutes.pin.reset.secretPhrase.path}</AppHeaderTitle>
            </AppHeaderData>
            <EnterSecretPhrase setPhrase={setPhrase} successTo={userRoutes.pin.reset.pin.path} />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.pin.reset.pin.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.Pin]}>
          <AppContainer headTitle={userRoutes.pin.reset.pin.title}>
            <AppHeaderData backButton helpButton>
              <AppHeaderTitle>{userRoutes.pin.reset.pin.title}</AppHeaderTitle>
            </AppHeaderData>
            <CreatePinRecover phrase={phrase} successTo={userRoutes.pin.reset.success.path} />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.pin.reset.success.path}>
        <AccessControlData needsAuthStatus={['authenticated']} needsFeature={[Feature.Pin]}>
          <AppContainer headTitle={userRoutes.pin.reset.success.title}>
            <AppHeaderData helpButton />
            <PinSuccessScene
              sceneTitle="PIN updated successfully"
              successTo={walletRoutes().base.path}
            />
          </AppContainer>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
